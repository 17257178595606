import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//import * as Swiper from './extern/swiper.js'; //quite
//import * as Cookie from './modules/cookie.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	new Modal();
}


jQuery(document).ready(function ($) {
	init();

	var swiper_projecten = new Swiper(".swiper-projecten", {
		slidesPerView: 1,
		spaceBetween: 30,
		navigation: {
			nextEl: ".swiper-projecten-next",
			prevEl: ".swiper-projecten-previous",
		},
		pagination: {
			el: ".swiper-pagination-projecten",
			clickable: true,
		},
		breakpoints: {
			640: {
				slidesPerView: 1,
				spaceBetween: 30,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 30,
			},

		},
	});

	var swiper_collegas = new Swiper(".swiper-collegas", {
		slidesPerView: 1,
		spaceBetween: 0,
		grabCursor: true,
		pagination: {
			el: ".swiper-pagination-collegas",
			clickable: true,
		},
		breakpoints: {
			640: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 0,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 0,
			},

		},
	});

});

